import auth from '@/middleware/auth'

export default [
	{
		path: '/profile',
		name: 'Perfil',
		icon: 'profile',
		meta: {
			title: 'profile'
		},
		beforeEnter: auth,
		component: () => import('@/pages/profile/Personal')
	},
	{
		path: '/profile/password',
		name: 'Segurança',
		icon: 'roles',
		meta: {
			title: 'security'
		},
		beforeEnter: auth,
		component: () => import('@/pages/profile/Password')
	},
	{
		path: '/profile/settings',
		name: 'Configurações',
		icon: 'settings',
		meta: {
			title: 'settings'
		},
		beforeEnter: auth,
		component: () => import('@/pages/profile/Settings')
	}
]

import auth from '@/middleware/auth'

export default [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'dashboard',
		meta: {
			title: 'dashboard'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Dashboard')
	},
	{
		path: '/companies:page?/:id?',
		name: 'Company',
		icon: 'company',
		meta: {
			title: 'company'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Company')
	},
	{
		path: '/roles/:page?/:id?',
		name: 'Roles',
		icon: 'roles',
		meta: {
			title: 'roles'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Roles')
	},
	{
		path: '/users/:page?/:id?',
		name: 'Users',
		icon: 'users',
		meta: {
			title: 'users'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Users')
	},
	{
		path: '/settings',
		name: 'Settings',
		icon: 'settings',
		meta: {
			title: 'settings'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Settings')
	},
	{
		path: '/places/:page?/:id?',
		name: 'Place',
		icon: 'place',
		meta: {
			title: 'places'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Place')
	},
	{
		path: '/templates/:page?/:id?',
		name: 'Template',
		icon: 'template',
		meta: {
			title: 'templates'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Template')
	},
	{
		path: '/screens/:page?/:id?',
		name: 'Screen',
		icon: 'screen',
		meta: {
			title: 'screens'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Screen')
	},
	{
		path: '/analytics/:place?',
		name: 'Analytic',
		icon: 'settings',
		meta: {
			title: 'analytics'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Analytics')
	},
	{
		path: '/camera',
		name: 'Camera',
		icon: 'settings',
		meta: {
			title: 'camera'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Camera')
	}
	/* auto-gerado */
]

import router from '@/router'
import Api from '@/services/api'
import axios from 'axios'
import Pusher from 'pusher-js'
import { ToastProgrammatic as Toast } from 'buefy'

export function getUserData({ commit }, force) {
	let user = localStorage.getItem('@podash:user')
	let permission = localStorage.getItem('@podash:permission')

	if (!user || process.env.VUE_APP_REQUEST_SIGLE_PROFILE == 'false' || force) {
		Api.get('user/me').then(({ data }) => {
			commit('setUser', data)
			commit('setPermissions', JSON.parse(data.permission.route))
		})
	} else {
		commit('setUser', JSON.parse(user))
		commit('setPermissions', JSON.parse(permission))
	}
}

export function deleteUserData(context) {
	localStorage.removeItem('@podash:token')
	localStorage.removeItem('@podash:user')
	localStorage.removeItem('@podash:permission')
	localStorage.removeItem('@podash:city')
	localStorage.removeItem('@podash:temperature')

	context.commit('setPermissions', null)
	context.commit('setUser', null)
}

export function getNotifications(context) {
	Api.get('notification/findAll').then(({ data }) => {
		context.commit('setNotifications', data)
		context.commit('setTotalNotifications', data.map((n) => n.pivot.read == 0).length)
	})
}

export function subscribeNotification({ commit, dispatch, getters }) {
	if (!getters.notificationStart) {
		if (process.env.VUE_APP_PUSHER_APP_KEY !== '') {
			const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
				cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER
			})

			const channel = pusher.subscribe('notification')

			channel.bind('notification', () => {
				dispatch('getNotifications')
			})

			pusher.connection.bind('state_change', (states) => {
				if (states.current == 'connected') {
					dispatch('getNotifications')
					commit('setNotificationStart', true)
				}
			})
		}
	}
}

export function checkUserByToken(context) {
	let uri = window.location.search.substring(1)
	let params = new URLSearchParams(uri)

	if (params.get('token')) {
		const token = params.get('token')

		context.commit('setLoadingRedirect', true)

		const config = {
			headers: { Authorization: `Bearer ${token}` }
		}

		axios.get(`${process.env.VUE_APP_BASE_API}user/me`, config)
			.then(({ data }) => {
				localStorage.setItem('@podash:token', token)
				context.commit('setPermissions', JSON.parse(data.permission.route))
				context.commit('setUser', data)

				router.push({ name: 'Dashboard' })
			})
			.catch(() => {
				localStorage.removeItem('@podash:token')
				context.commit('setPermissions', null)
				context.commit('setUser', null)

				Toast.open({
					message: 'Dados inválidos',
					type: 'is-danger',
					position: 'is-bottom'
				})
			})
			.then(() => {
				context.commit('setLoadingRedirect', false)
			})
	} else {
		context.commit('setLoadingRedirect', false)
	}
}

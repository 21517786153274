// https://logaretm.github.io/vee-validate/guide/rules.html
import { required, confirmed, length, email, alpha, alpha_num, digits, numeric, min, max, max_value } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('required', {
	...required,
	message: 'Esse campo é obrigatório'
})

extend('email', {
	...email,
	message: 'Digite um e-mail válido'
})

extend('alpha', {
	...alpha,
	message: 'Digite somente letras'
})

extend('alpha_num', {
	...alpha_num,
	message: 'Digite somente numeros ou caracteres especiais'
})

extend('confirmed', {
	...confirmed,
	message: 'O campo de confirmação está diferente'
})

extend('length', {
	...length,
	message: 'Selecione pelo menos {length} opções'
})

extend('min', {
	...min,
	message: 'Digite pelo menos {length} caracteres'
})

extend('max', {
	...max,
	message: 'Digite no máximo {length} caracteres'
})

extend('digits', {
	...digits,
	message: 'Insira apenas números'
})

extend('numeric', {
	...numeric,
	message: 'Insira apenas números'
})

extend('max_value', {
	...max_value,
	message: 'Your name must have {length} characters'
})

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pt from '@/language/pt.json'
import en from '@/language/en.json'
import es from '@/language/es.json'

Vue.use(VueI18n)

const messages = {
    'pt': pt,
    'en': en,
    'es': es
}

export default new VueI18n({
    locale: 'pt',
    fallbackLocale: 'pt',
    messages
})

import Vue from 'vue'
import App from './App'
import Buefy from 'buefy'
import VueMask from 'v-mask'
import router from './router'
import Roles from './mixins/roles'
import store from './store'
import './validation'
import VueMeta from 'vue-meta'
import i18n from '@/language/i18n'

Vue.config.productionTip = false

Vue.mixin(Roles)
Vue.use(Buefy)
Vue.use(VueMask)
Vue.use(VueMeta)

new Vue({
	i18n,
	router,
	store,
	render: h => h(App),
	callRoleMixin: true
}).$mount('#app')

Vue.prototype.$callRoleMixin = false
Vue.config.devtools = true

<template>
	<router-view />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { enable as enableDarkMode, setFetchMethod, disable as disableDarkMode } from 'darkreader'

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

export default {
	metaInfo: {
		title: `${process.env.VUE_APP_NAME} • ${process.env.VUE_APP_FORMAL_NAME}`
	},
	created() {
		this.checkUserByToken()
	},
	methods: {
		...mapActions('user', [
			'checkUserByToken'
		]),
		changeTheme(theme) {
			setFetchMethod(window.fetch)

			if (theme == 'dark') {
				enableDarkMode({}, {
					disableStyleSheetsProxy: true
				})
			} else {
				disableDarkMode()
			}
		}
	},
	computed: {
		...mapState('user', [
			'theme'
		])
	},
	watch: {
		theme(v) {
			this.changeTheme(v)
		}
	}
}
</script>

<style lang="sass">
@import 'assets/styles/application.sass'
</style>
